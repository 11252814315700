<template>
  <div>
    <!-- <Header></Header> -->
    <div class="tipContent" v-if="isTip">
      您当前使用的浏览器版本过低，可能会出现界面显示异常或功能无法正常使用等问题，建议您更换为更高版本的浏览器
    </div>
    <div class="header" id="header" :class="{ 'header-bg': isScrolled }">
      <div class="head-content" :class="{ 'head-content-bg': isScrolled }">
        <div class="ai-logo">
          <a href="">
            <img
              v-if="curHost == 'www' || curHost == 'app'"
              src="../assets/logo/qingjing/ai-img-logo1.png"
              alt=""
            />
            <img
              v-if="curHost == 'shadow'"
              src="../assets/logo/zhuoyao/logo.png"
              alt=""
            />
            <img v-if="curHost == 'imouse'" src="" alt="" />
            <img
              v-if="curHost == 'dido'"
              src="../assets/logo/dido/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'eweadn'"
              src="../assets/logo/eweadn/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'dario'"
              src="../assets/logo/dario/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'yundesk'"
              src="../assets/logo/yundesk/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'greatwall'"
              src="../assets/logo/greatwall/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'xbantu'"
              src="../assets/logo/xbantu/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'aioa'"
              src="../assets/logo/aioa/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'metav'"
              src="../assets/logo/metav/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'wo'"
              src="../assets/logo/wo/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'taiguo'"
              src="../assets/logo/taiguo/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'jiabo'"
              src="../assets/logo/jiabo/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'zhunxin'"
              src="../assets/logo/zhunxin/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'jiyuai'"
              src="../assets/logo/jiyuai/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'shinco'"
              src="../assets/logo/shinco/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'miaowang' || curHost == 'mifi'"
              src="../assets/logo/miaowang/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'ysd'"
              src="../assets/logo/ysd/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'akpnai'"
              src="../assets/logo/akpnai/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'hyundai'"
              src="../assets/logo/hyundai/logo.png"
              alt=""
            />
            <img
              v-if="curHost == 'xunxiang'"
              src="../assets/logo/xunxiang/ai-img-logo.png"
              alt=""
            />
          </a>
        </div>
        <div class="head-nav">
          <!-- <ul>
						<li class="nav-text"><router-link to="/">首页</router-link></li>
						<li class="nav-text" @mouseenter="enter(1)" @mouseleave="leave(1)">
							<a href="javascript:;">解决方案</a>
							<div class="secone-nav box-shadow" v-show="seenSolution" style="width: 180px;">
								<p><router-link to="/GasSolution">智慧燃气解决方案</router-link></p>

							</div>
						</li>
						<li class="nav-text" @mouseenter="enter(2)" @mouseleave="leave(2)">
							<a href="javascript:;">支持与服务</a>
							<div class="secone-nav box-shadow" v-show="seenService">
								<p><router-link to="/Service">支持与服务</router-link></p>
							</div>
						</li>
						<li class="nav-text" @mouseenter="enter(3)" @mouseleave="leave(3)">
							<a href="javascript:;">关于我们</a>
							<div class="secone-nav box-shadow" v-show="seenAbout">
								<p><router-link to="/About">关于我们</router-link></p>
							</div>
						</li>
					</ul> -->
        </div>
        <div
          class="language-change"
          @mouseenter="languageEnter"
          @mouseleave="languageLeave"
        >
          <a class="lang-title" href="javascript:;">{{ $t("lang") }}</a>
          <div class="language-level" v-show="showLanguage">
            <ul v-for="(lang, index) in $t('languages')" :key="index">
              <li>
                <a
                  class="lang"
                  href="javascript:;"
                  @click="changeLanguage(lang.value)"
                  >{{ lang.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="foot" v-if="isFooter">
      <div class="foot-content">
        <div class="ai-logo-foot">
          <img
            v-if="curHost == 'www' || curHost == 'app'"
            src="../assets/logo/qingjing/ai-img-logo1.png"
            alt=""
          />
          <img
            v-if="curHost == 'shadow'"
            src="../assets/logo/zhuoyao/logo-w.png"
            alt=""
          />
          <!-- <img v-if="curHost == 'imouse'" src="../assets/logo/cleverAI/logo-w.png" alt=""> -->
          <img
            v-if="curHost == 'dido'"
            src="../assets/logo/dido/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'eweadn'"
            src="../assets/logo/eweadn/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'dario'"
            src="../assets/logo/dario/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'yundesk'"
            src="../assets/logo/yundesk/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'greatwall'"
            src="../assets/logo/greatwall/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'xbantu'"
            src="../assets/logo/xbantu/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'aioa'"
            src="../assets/logo/aioa/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'metav'"
            src="../assets/logo/metav/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'wo'"
            src="../assets/logo/wo/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'taiguo'"
            src="../assets/logo/taiguo/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'jiabo'"
            src="../assets/logo/jiabo/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'zhunxin'"
            src="../assets/logo/zhunxin/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'jiyuai'"
            src="../assets/logo/jiyuai/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'shinco'"
            src="../assets/logo/shinco/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'miaowang' || curHost == 'mifi'"
            src="../assets/logo/miaowang/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'ysd'"
            src="../assets/logo/ysd/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'akpnai'"
            src="../assets/logo/akpnai/logo-w.png"
            alt=""
          />
          <img
            v-if="curHost == 'hyundai'"
            src="../assets/logo/hyundai/logo-w.png"
            alt=""
          />

          <img
            v-if="curHost == 'xunxiang'"
            src="../assets/logo/xunxiang/ai-img-logo.png"
            alt=""
          />
        </div>
        <div class="foot-nav">
          <div class="foot-nav-left">
            <ul class="foot-nav-ul">
              <li
                class="contact-way"
                v-if="curHost == 'www' || curHost == 'app'"
              >
                <h5>{{ $t("contact.link") }}</h5>
                <p>
                  <a href="https://www.aioffice.ltd/" target="_blank">{{
                    $t("contact.oldAddress")
                  }}</a>
                </p>
              </li>
              <li class="qrcode" v-if="curHost == 'www' || curHost == 'app'">
                <div class="qrcode-img">
                  <img src="../assets/images/ai-img-douyin-bg.png" alt="" />
                  <p>{{ $t("contact.douyin") }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="foot-nav-right">
            <div class="work-time worktime">
              <h5>{{ $t("contact.worktime") }}</h5>
              <p>{{ $t("contact.timeFrame") }}</p>
            </div>
            <ul class="foot-nav-qrcode">
              <li
                class="qrcode"
                v-if="['www', 'app', 'jiyuai', 'akpnai'].includes(curHost)"
              >
                <div class="qrcode-img">
                  <img
                    v-if="['www', 'app'].includes(curHost)"
                    src="../assets/images/ai-img-video.jpg"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'jiyuai'"
                    src="../assets/logo/jiyuai/img-video.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'akpnai'"
                    src="../assets/logo/akpnai/img-video.png"
                    alt=""
                  />
                </div>
                <p>{{ $t("contact.video") }}</p>
              </li>
              <li
                class="qrcode"
                v-if="['imouse', 'eweadn', 'dario', 'jiyuai'].includes(curHost)"
              >
                <div class="qrcode-img">
                  <img
                    v-if="['eweadn', 'dario'].includes(curHost)"
                    src="../assets/images/ai-img-douyin-bg.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'imouse'"
                    src="../assets/logo/cleverAI/img-douyin.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'jiyuai'"
                    src="../assets/logo/jiyuai/img-douyin.png"
                    alt=""
                  />
                </div>
                <p>{{ $t("contact.douyin") }}</p>
              </li>
              <li
                class="qrcode"
                v-if="
                  [
                    'www',
                    'app',
                    'imouse',
                    'eweadn',
                    'dario',
                    'taiguo',
                    'akpnai',
                  ].includes(curHost)
                "
              >
                <div class="qrcode-img">
                  <img
                    v-if="['www', 'app', 'eweadn', 'dario'].includes(curHost)"
                    src="../assets/images/ai-img-qcode.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'imouse'"
                    src="../assets/logo/cleverAI/img-qcode.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'dido'"
                    src="../assets/logo/dido/img-qcode.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'taiguo'"
                    src="../assets/logo/taiguo/img-qcode.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'akpnai'"
                    src="../assets/logo/akpnai/img-qcode.png"
                    alt=""
                  />
                </div>
                <p>{{ $t("contact.weChatAccount") }}</p>
              </li>
              <li
                class="qrcode"
                v-if="
                  [
                    'www',
                    'app',
                    'imouse',
                    'eweadn',
                    'dario',
                    'akpnai',
                  ].includes(curHost)
                "
              >
                <div class="qrcode-img">
                  <img
                    v-if="['www', 'app', 'eweadn', 'dario'].includes(curHost)"
                    src="../assets/images/ai-img-service.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'imouse'"
                    src="../assets/logo/cleverAI/img-service.png"
                    alt=""
                  />
                  <img
                    v-if="curHost == 'akpnai'"
                    src="../assets/logo/akpnai/img-service.png"
                    alt=""
                  />
                </div>
                <p>{{ $t("contact.customerService") }}</p>
              </li>
              <li class="qrcode" v-if="['jiyuai'].includes(curHost)">
                <div class="qrcode-img">
                  <img src="../assets/logo/jiyuai/img-service.png" alt="" />
                </div>
                <p>{{ $t("contact.business") }}</p>
              </li>
               <!-- 二维码组-新 -->
              <template v-if="['xunxiang'].includes(curHost)">
                <li
                  class="qrcode"
                  v-for="(image, index) in codeList"
                  :key="index"
                >
                  <div class="qrcode-img">
                    <img :src="image" alt="Image" />
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <p class="record-no">
          <a href="https://beian.miit.gov.cn/"
            >Copyright@ 粤ICP备2022076506号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//import Header from './content/Header.vue'
import store from "@/store/index.js";

export default {
  components: {
    //Header,
  },
  data() {
    return {
      scroll: "",
      isScrolled: false,
      seenSolution: false,
      seenService: false,
      seenAbout: false,
      lang: "",
      showLanguage: false,
      isTip: false, //浏览器版本判断
      curHost: "",
      isFooter: true,
      codeList: [],
    };
  },
  created() {
    //获取浏览器信息，并判断版本
    let DEFAULT_VERSION = 9.0;
    let ua = navigator.userAgent.toLowerCase();
    let isIE = ua.indexOf("msie") > -1;
    let version = "";
    console.log(isIE + "0000");
    if (isIE) {
      version = ua.match(/msie([\d.]+)/)[1];
      if (version <= DEFAULT_VERSION) {
        this.isTip = true;
      }
    }
    //获取链接
    this.getPageUrl();
  },
  mounted() {
    this.isFooter = window.location.pathname === "/aioffice" ? false : true;

    if (this.curHost == "jiyuai") {
      document.title = "JIYUAI"; // 浏览器标签title
    } else if (this.curHost == "miaowang" || this.curHost == "mifi") {
      document.title = "AI办公助手";
    } else if (this.curHost == "lefan") {
      document.title = "AI-Mouse";
    } else if (this.curHost == "yff") {
      document.title = "云非凡";
    } else {
      document.title = "AI-Office"; // 浏览器标签title
    }
    //监听鼠标滚动事件
    window.addEventListener(
      "scroll",
      this.debounce(this.handleScroll, 30),
      true
    ) ||
      window.addEventListener(
        "DOMMouseScroll",
        this.debounce(this.handleScroll, 30),
        false
      );
  },
  methods: {
    //函数防抖
    debounce(func, wait) {
      let timeout;
      return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    //获取页面链接
    getPageUrl() {
      let url = window.location.href;
      console.log(url);
      const urlObject = new URL(url);
      // const urlObject = new URL("https://newland.aioffice.ltd/");

      if (urlObject.hostname != "") {
        const hostName = urlObject.hostname.split(".")[0];

        this.curHost = hostName;

        console.log(this.curHost);
        // 二级域名
        store.commit("setCurrentHost", this.curHost);
        // 全域名
        store.commit("setCurrentFullDomainName", urlObject.hostname);
      }

      //获取二维码组
      if (["xunxiang"].includes(this.curHost)) {
        this.codeList = this.getImagesCode(this.curHost);
      }
    },
    //判断滚动方向
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        //document.querySelector('#header').style.backgroundImage = 'rgba(0,0,0,0.2)';
        this.isScrolled = true;
      } else {
        //document.querySelector('#header').style.backgroundColor = 'transparent';
        this.isScrolled = false;
      }
    },
    enter(index) {
      if (index == 1) this.seenSolution = true;
      else if (index == 2) this.seenService = true;
      else if (index == 3) this.seenAbout = true;
    },
    leave(index) {
      if (index == 1) this.seenSolution = false;
      else if (index == 2) this.seenService = false;
      else if (index == 3) this.seenAbout = false;
    },
    //语言界面
    languageEnter() {
      this.showLanguage = true;
    },
    languageLeave() {
      this.showLanguage = false;
    },
    changeLanguage(lang) {
      //console.log(lang)
      if (this.$i18n.locale != lang) {
        this.$root.$i18n.locale = lang;
        store.commit("setCurrentLanguage", lang);
      }
    },

    // 获取指定文件夹中的所有图片
    getImagesCode(folder) {
      let context;
      switch (folder) {
        case "xunxiang":
          context = require.context(
            "../assets/code/xunxiang",
            true,
            /\.(png|jpg|jpeg|svg)$/
          );
          break;

        default:
          return [];
      }
      // 获取所有code图片的路径
      const images = context.keys().map((key) => context(key)); // 直接使用 context(key) 获取图片路径
      return images;
    },
  },
};
</script>

<style lang="scss"></style>
